import { useEffect, useState } from 'react';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies';
import { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { getFiatCurrencyFromCode } from './utils';

// useFiatCurrencyFromCode will probably be removed soon
// https://linear.app/noah-hq/issue/FE-1204/check-usefiatcurrencyfromcode-usage
const fallback = {
  code: FiatCurrencyCode.USD,
  decimals: 2,
  label: 'United States Dollar',
  symbol: '$',
};

export function useFiatCurrencyFromCode(code: string): TpFiatCurrency {
  const [fiatCurrency, setFiatCurrency] = useState<TpFiatCurrency>(fallback);

  useEffect((): void => {
    async function getFiatCurrency(): Promise<void> {
      const fiatCurrencyData = await getFiatCurrencyFromCode(code, fallback);
      setFiatCurrency(fiatCurrencyData);
    }
    void getFiatCurrency();
  }, [code]);

  return fiatCurrency;
}
